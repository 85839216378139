import React from 'react'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { css } from '@emotion/core';

export default function Cover () {

    return (
        <div css={css`
            flex: 1;
        `} className="artech-plaza__cover">
            <div className="artech-plaza__cover-figure">
                <Parallax className="item one" y={[0, '-50%']} x={['-20%', '20%']} tagOuter="div">
                    <img src="/images/art4.png" alt=""/>
                </Parallax>
                <Parallax className="item two" y={[-20, '30%']} x={[-10, '-20%']} tagOuter="div">
                    <img src="/images/art2.png" alt=""/>
                </Parallax>
                <Parallax className="item three" y={[-30, '-10%']} x={[20, -20]} tagOuter="div">
                    <img src="/images/art3.png" alt=""/>
                </Parallax>
            </div>
        </div>
    )
}