import React, { useState } from 'react';
import { FirebaseContext } from "gatsby-plugin-firebase"
import moment from 'moment'
import { css } from '@emotion/core';

export default function ContactForm (props) {

    const firebase = React.useContext(FirebaseContext)

    const [name,setname] = useState('')
    const [phone,setphone] = useState('')
    const [email,setemail] = useState('')
    const [message,setmessage] = useState('')

    return (
        <form onSubmit={e => e.preventDefault() && false}>
            <div className="input-item">
                <label>Nombre</label>
                <input value={name} onChange={e => setname(e.target.value)} placeholder="Nombre" type="text"/>
            </div>
            <div className="input-item">
                <label>Teléfono</label>
                <input value={phone} onChange={e => setphone(e.target.value)} placeholder="Teléfono" type="text"/>
            </div>
            <div className="input-item">
                <label>Email</label>
                <input value={email} onChange={e => setemail(e.target.value)} placeholder="Email" type="email"/>
            </div>
            <div className="input-item">
                <label>Mensaje</label>
                <textarea value={message} onChange={e => setmessage(e.target.value)} placeholder="Mensaje" rows="4"/>
            </div>
            <div className="input-item">
                <button css={css`
                    cursor: pointer;
                `} onClick={handleSubmit} type="submit">Enviar Mensaje</button>
            </div>
        </form>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                var item = {
                    name,
                    email,
                    message,
                    phone,
                    timestamp: moment().valueOf(),
                    page: props.page
                }
                const ref = firebase.firestore().collection('contact').doc()
                item.id = ref.id
                const mail = {
                    email,
                    subject: `Columna`,
                    text: `Columna`,
                    message: `
                    <div style="max-width:600px; width:100%;">
                        <p>Hi!</p>
                        <p>Than you for your message. We will be in touch shortly.</p>
                    </div>
                    `,
                    status: 'pending'
                }
                return ref.set(item).then(() => {
                    firebase.firestore().collection('emails').doc().set(mail).then(() => {
                        const emailRef = firebase.firestore().collection('emails').doc()
                        item.message = `
                            <div style="max-width:600px; width:100%;">
                                <p>Alguien solicitó información de un proyecto.</p>
                                <ul>
                                    <li><b>Proyecto: </b> ${props.page}</li>
                                    <li><b>Nombre de Cliente: </b> ${name}</li>
                                    <li><b>Email de Cliente: </b> ${email}</li>
                                    <li><b>Teléfono de Cliente: </b> ${phone}</li>
                                    <li><b>Mensaje de Cliente: </b> ${message}</li>
                                </ul>
                                <p>Gracias,</p>
                                <p>Columna Mx.</p>
                            </div>
                        `
                        item.email = 'alejandro@columnamx.com, info@columnamx.com, sam@columnamx.com'
                        // item.email = 'ebernal.n98@gmail.com'
                        item.subject = 'Solicitud de información de proyecto'
                        item.text = 'Solicitud de información de proyecto'
                        emailRef.set(item).then(() => {
                            setname('')
                            setemail('')
                            setmessage('')
                            setphone('')
                            alert('Tu mensaje ha sido enviado, un asesor se pondrá en contacto contigo')
                        })
                    })
                })
            } else {
                alert('Escribe un nombre, email y mensaje para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}