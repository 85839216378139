import React from 'react';
import { Link } from 'gatsby'
import { css } from '@emotion/core';

export default (props) => {

    return (
        <Link css={css`
            position: fixed;
            top: 2.5rem;
            right: 2.5rem;
            z-index: 99;
        `} to={props.origin ? props.origin : '/'}>
            <img css={css`
                width: 15rem;
            `} src="/images/logo-hor.png" alt="Columna"/>
        </Link>
    )
}