import React from 'react'
import { Link } from 'gatsby'

import { ParallaxProvider } from 'react-scroll-parallax';
import Cover from '../components/artech/Cover';
import LogoHor from '../components/LogoHor';
import { css } from '@emotion/core';
import ContactForm from '../components/ContactForm';

export default (props) => {

    return (
        <ParallaxProvider>
            <LogoHor origin={props.location.state ? props.location.state.prevPath : '/'} />
            <div className="artech-plaza" css={css`
                display: flex;
                height: 100vh;
                @media only screen and (max-width: 26.5625em) {
                    flex-direction: column;
                    height: unset;
                }
            `}>
                <Cover/>
                <div css={css`
                    flex: 1;
                    overflow-y: auto;
                    padding: 4.5rem 2.5rem;
                `}>
                    <div css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    `} className="artech-plaza__about">
                        <div css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        `} className="artech-plaza__about-container">
                            <div className="content">
                                <h1>¿Qué es Artech Plaza?</h1>
                                <p>ARTECH PLAZA es un conjunto de instalaciones artísticas enfocadas en la relación entre el arte y la tecnología.</p>
                                <p>El evento presenta en la CDMX el futuro del arte siendo de los primeros proyectos en darle un lugar de exposición a artistas que han innovado con nuevas propuestas artísticas a través de los visuales, los algoritmos, la robótica, la realidad virtual, el mapping, entre otras.</p>
                                <p>ARTECH PLAZA es uno de los eventos de arte más importantes del año ya que marca tendencia por darle exposición a la tecnología como medio artístico.</p>
                            </div>
                        </div>
                    </div>
                    <div className="artech-plaza__contact">
                        <div className="artech-plaza__contact-container">
                            <h3>¿Quieres saber más de nuestros eventos?</h3>
                            <ContactForm page="Artech Plaza" />
                            <Link to="/"> {'<'} Regresar a columna</Link>
                        </div>
                    </div>
                </div>
            </div>
        </ParallaxProvider>
    )
}